import { APPLY_FORM_CONTAINER_ID } from "@constants/index";
import { DOMAIN } from "../../constants";

// data-job-job_link
export default function createJobLink() {
  const formContainer = document.getElementById(APPLY_FORM_CONTAINER_ID);

  if (window.OMJobApplyInfo && formContainer) {
    const { itemName, jobOfferPath } = window.OMJobApplyInfo;

    const jogLinks = formContainer.querySelectorAll("[data-job-job_link]");

    jogLinks.forEach((jobLink) => {
      const tagName = jobLink.tagName.toLowerCase();
      if (tagName === "a") {
        const text = document.createTextNode(itemName);
        jobLink.appendChild(text);
        jobLink.setAttribute("href", `${DOMAIN}${jobOfferPath}`);
      } else {
        const text = document.createTextNode(itemName);
        jobLink.appendChild(text);
      }
    });
  }
}
