import doGetRequest from "@modules/doGetRequest";
import createCountCitySelect from "@ElementMaker/job/createCountCitySelect";

export function changePrefSelect(value, queryString) {
  const prefectureNumber = value;

  doGetRequest({
    path: `/api/v1/getJobOfferSearchResult/?${queryString}&work_type=&prefecture=${prefectureNumber}&city=`,
    successFunc: (res) => {
      const { city_data } = res.data;

      createCountCitySelect(city_data);
    },
    errorFunc: (err) => {
      console.error(err);
    },
    finallyFunc: () => {},
  });
}
