export default function setInitialPrefecture(prefecture) {
  if (!prefecture) {
    return;
  }
  const prefInputMode =
    window.OMJobListOptions &&
    window.OMJobListOptions.prefectureSettings &&
    window.OMJobListOptions.prefectureSettings.inputMode;

  if (prefInputMode === "checkbox") {
    const prefectures = prefecture.split(",");
    const inputs = document.getElementsByName("om-forms-prefecture");

    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i];
      if (prefectures.includes(input.value)) {
        input.checked = true;
      }
    }
  } else {
    const element = document.querySelector("[data-form-count-prefecture]");

    if (element) {
      element.value = String(prefecture);
      element.dispatchEvent(new Event("change"));
    }
  }
}
