import { FORM_CONTAINER_ID } from "@constants/index";

export default function createQueryInput() {
  const formContainer = document.getElementById(FORM_CONTAINER_ID);

  if (formContainer) {
    const queries = formContainer.querySelectorAll("[data-form-query]");

    queries.forEach((query) => {
      if (query.tagName === "INPUT") {
        query.setAttribute("type", "text");
      } else {
        const newInput = document.createElement("input");
        newInput.setAttribute("type", "text");
        newInput.setAttribute("placeholder", "検索ワードを入力");
        query.appendChild(newInput);
      }
    });
  }
}
