import axios from "axios";
import { DOMAIN } from "../constants/index";

export default function doPostRequest({
  path,
  payload,
  successFunc,
  errorFunc,
  finallyFunc,
}) {
  axios
    .post(`${DOMAIN}${path}`, payload)
    .then(successFunc)
    .catch(errorFunc)
    .finally(finallyFunc);
}
