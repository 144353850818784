import getFormValue from "@modules/getFormValue";
import JobList from "@pages/JobList";
import getQueryString from "@modules/getQueryString";

function getNoQueryUrl() {
  const urlWithoutQuery =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname;

  return urlWithoutQuery;
}

export function clickJobSearch() {
  const noQueryUrl = getNoQueryUrl();

  const allowPageReload = window.OMJobListOptions.allowPageReload || false;
  const paramObj = getFormValue();
  paramObj.p = 0;

  if (allowPageReload) {
    const queryString = new URLSearchParams(paramObj).toString();
    window.location.href = `${noQueryUrl}?${queryString}`;
  }

  JobList({
    params: paramObj,
    is_filter: true,
  });
}

export function clickJobPage() {
  const page = this.dataset.jobsPage;
  const noQueryUrl = getNoQueryUrl();

  const allowPageReload = window.OMJobListOptions.allowPageReload || false;
  const paramObj = getFormValue();

  if (allowPageReload) {
    const queryParam = getQueryString();
    queryParam.p = parseInt(page) - 1;
    const queryString = new URLSearchParams(queryParam).toString();
    window.location.href = `${noQueryUrl}?${queryString}`;
  }

  if (page) {
    paramObj.p = parseInt(page) - 1;
    JobList({
      params: paramObj,
      is_filter: true,
    });
  }
}
