import { PREFECTURES, FORM_CONTAINER_ID } from "@constants/index";

export default function createPrefectureInput() {
  const formContainer = document.getElementById(FORM_CONTAINER_ID);

  if (formContainer) {
    const prefectureInput = formContainer.querySelector(
      "[data-form-prefecture]"
    );

    if (prefectureInput) {
      prefectureInput.innerHTML = "";
      const labelsFragment = document.createDocumentFragment();

      const targetPrefectures =
        window.OMJobListDefaultParams &&
        window.OMJobListDefaultParams.target_prefectures;

      let prefectures = PREFECTURES;
      if (targetPrefectures) {
        const targetPrefs = targetPrefectures.split(",");
        prefectures = PREFECTURES.filter((prefecture) => {
          const [number, _] = prefecture;
          return targetPrefs.includes(String(number));
        });
      }

      prefectures.forEach((prefecture) => {
        const [number, label] = prefecture;

        const newInput = document.createElement("input");
        newInput.setAttribute("name", "om-forms-prefecture");
        newInput.setAttribute("type", "checkbox");
        newInput.setAttribute("value", number);

        const newSpan = document.createElement("span");
        const prefectureText = document.createTextNode(label);
        newSpan.appendChild(prefectureText);

        const newLabel = document.createElement("label");
        newLabel.appendChild(newInput);
        newLabel.appendChild(newSpan);

        labelsFragment.appendChild(newLabel);
      });
      prefectureInput.appendChild(labelsFragment);
    }
  }
}
