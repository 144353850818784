import axios from "axios";
import { DOMAIN } from "../constants/index";

export default function doGetRequest({
  path,
  successFunc,
  errorFunc,
  finallyFunc,
}) {
  axios
    .get(`${DOMAIN}${path}`, {
      headers: {
        "Content-type": "application/json",
      },
    })
    .then(successFunc)
    .catch(errorFunc)
    .finally(finallyFunc);
}
