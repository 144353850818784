import { FORM_CONTAINER_ID, WORK_JOB_VERSION } from "@constants/index";

function getCheckedCheckboxValues({ checkboxElements }) {
  if (!checkboxElements) {
    return null;
  }
  var checkedValues = [];

  for (var i = 0; i < checkboxElements.length; i++) {
    if (checkboxElements[i].checked) {
      checkedValues.push(checkboxElements[i].value);
    }
  }

  return checkedValues;
}

export default function getFormValue() {
  const formContainer = document.getElementById(FORM_CONTAINER_ID);

  if (formContainer) {
    const prefSelect = formContainer.querySelector("[data-form-prefecture]");
    const citySelect = formContainer.querySelector("[data-form-city]");
    const worktypeSelect = formContainer.querySelector("[data-form-workType]");
    const jobtypeSelect = formContainer.querySelector("[data-form-jobType]");
    const querySelect = formContainer.querySelector(
      "[data-form-query] > input,input[data-form-query]"
    );
    const beginnerCheck = formContainer.querySelector(
      "[data-form-beginnerCheck]"
    );
    const hiringSystem = formContainer.querySelector(
      "[data-form-hiringSystem]"
    );

    const paramObj = { ver: WORK_JOB_VERSION };

    if (prefSelect) {
      const inputMode =
        window.OMJobListOptions &&
        window.OMJobListOptions.prefectureSettings &&
        window.OMJobListOptions.prefectureSettings.inputMode;

      if (inputMode === "checkbox") {
        const checkboxPrefElements = document.getElementsByName(
          "om-forms-prefecture"
        );

        if (checkboxPrefElements && checkboxPrefElements.length > 0) {
          const checkedPrefValues = getCheckedCheckboxValues({
            checkboxElements: checkboxPrefElements,
          });

          paramObj.prefecture = checkedPrefValues.join(",");
        }
      } else {
        paramObj.prefecture = prefSelect.value;
      }
    }

    if (citySelect) {
      paramObj.city = citySelect.value;
    }

    if (worktypeSelect) {
      paramObj.work_type = worktypeSelect.value;
    }

    if (jobtypeSelect) {
      paramObj.job_type = jobtypeSelect.value;
    }

    if (querySelect) {
      paramObj.query = querySelect.value;
    }

    if (beginnerCheck) {
      paramObj.is_beginner_check = beginnerCheck.checked ? 1 : 0;
    }

    if (hiringSystem) {
      const checkboxElements = document.getElementsByName(
        "om-forms-hiringSystem"
      );

      if (checkboxElements && checkboxElements.length > 0) {
        const checkedValues = getCheckedCheckboxValues({ checkboxElements });

        paramObj.hiring_system = checkedValues.join(",");
      }
    }

    return paramObj;
  } else {
    return {};
  }
}
