export default function setJobValue(job, itemList, formatterFunc, template) {
  itemList.forEach((itemLabel) => {
    const items = template.querySelectorAll(`[data-job-${itemLabel}]`);
    items.forEach((item) => {
      if (item) {
        if (formatterFunc) {
          item.innerText = formatterFunc(job[itemLabel]);
        } else {
          item.innerText = job[itemLabel];
        }
      }
    });
  });
}
