import { APPLY_FORM_CONTAINER_ID } from "@constants/index";
import Text from "@ElementMaker/basic/Text";

export default function createEmailInput() {
  const formContainer = document.getElementById(APPLY_FORM_CONTAINER_ID);

  if (formContainer) {
    const emailContainer = formContainer.querySelector(`[data-form-email]`);
    new Text("email", emailContainer, "メールアドレスを入力").create();
  }
}
