class Textarea {
  constructor(name, inputContainer, placeholder) {
    this.name = name;
    this.inputContainer = inputContainer;
    this.placeholder = placeholder;
  }

  create() {
    if (this.inputContainer) {
      const textarea = document.createElement("textarea");
      textarea.setAttribute("name", this.name);
      textarea.setAttribute("placeholder", this.placeholder);

      this.inputContainer.appendChild(textarea);
    }
  }
}

export default Textarea;
