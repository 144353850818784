import { APPLY_FORM_CONTAINER_ID } from "@constants/index";
import Select from "@ElementMaker/basic/Select";
import range from "@utils/range";

const yearEnd = new Date().getFullYear();
const years = range({ start: yearEnd - 100, end: yearEnd - 12 });
const months = range({ start: 1, end: 12 });

const yearOptions = years.map((year) => {
  return [year, String(year)];
});
const monthOptions = months.map((month) => {
  return [month, String(month)];
});

function getLastMonthDate(month) {
  let lastMonthDate = 31;
  if (month == "2") {
    lastMonthDate = 29;
  } else if (["4", "6", "9", "11"].includes(month)) {
    lastMonthDate = 30;
  }

  return lastMonthDate;
}

function handleMonthChange(e, dateContainer) {
  const month = e.target.value;

  const lastMonthDate = getLastMonthDate(month);
  const dates = range({ start: 1, end: lastMonthDate });
  const dateOptions = dates.map((date) => {
    return [date, String(date)];
  });

  const selectElement = dateContainer.querySelector("select");
  new Select("date", null, dateOptions, null).update(selectElement);
}

export default function createBirthDateInput() {
  const formContainer = document.getElementById(APPLY_FORM_CONTAINER_ID);

  if (formContainer) {
    const yearContainer = formContainer.querySelector(`[data-form-year]`);
    const monthContainer = formContainer.querySelector(`[data-form-month]`);
    const dateContainer = formContainer.querySelector(`[data-form-date]`);

    new Select("year", yearContainer, yearOptions, null).create();
    new Select("month", monthContainer, monthOptions, function (e) {
      handleMonthChange(e, dateContainer);
    }).create();

    const dates = range({ start: 1, end: 31 });
    const dateOptions = dates.map((date) => {
      return [date, String(date)];
    });
    new Select("date", dateContainer, dateOptions, null).create();
  }
}
