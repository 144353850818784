import { FORM_CONTAINER_ID } from "@constants/index";
import { clickJobSearch } from "@handlers/job/list/click";

export default function createJobListSubmit({ searchButtonText }) {
  const formContainer = document.getElementById(FORM_CONTAINER_ID);

  if (formContainer) {
    const submits = formContainer.querySelectorAll("[data-form-submit]");

    submits.forEach((submit) => {
      if (submit.tagName === "BUTTON") {
        submit.setAttribute("type", "submit");
        submit.addEventListener("click", clickJobSearch, false);
      } else {
        const jobListButton = document.createElement("button");
        const buttonLabel = document.createTextNode(searchButtonText);

        jobListButton.setAttribute("type", "submit");
        jobListButton.appendChild(buttonLabel);
        jobListButton.addEventListener("click", clickJobSearch, false);

        submit.appendChild(jobListButton);
      }
    });
  }
}
