import { CONTAINER_ID } from "@constants/index";

export default function scrollToListHead(scrollTargetID) {
  const SCROLL_TARGET_ID = scrollTargetID || CONTAINER_ID;
  const container = document.getElementById(SCROLL_TARGET_ID);

  if (container) {
    container.scrollIntoView({ behavior: "smooth", inline: "nearest" });
  }
}
