import { APPLY_FORM_CONTAINER_ID } from "@constants/index";
import Text from "@ElementMaker/basic/Text";

export default function createKanaNameInput() {
  const formContainer = document.getElementById(APPLY_FORM_CONTAINER_ID);

  if (formContainer) {
    const lastNameContainer = formContainer.querySelector(
      `[data-form-last_name_kana]`
    );
    const firstNameContainer = formContainer.querySelector(
      `[data-form-first_name_kana]`
    );

    new Text(
      "last_name_kana",
      lastNameContainer,
      "名前を入力（セイ）"
    ).create();
    new Text(
      "first_name_kana",
      firstNameContainer,
      "名前を入力（メイ）"
    ).create();
  }
}
