
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function formatSalary(salary){
    if (salary){
        return numberWithCommas(salary) + '円'
    } else {
        return ''
    }
}