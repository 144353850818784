import { FORM_CONTAINER_ID } from "@constants/index";
import getFormValue from "@modules/getFormValue";

export default function createJobListButton() {
  const formContainer = document.getElementById(FORM_CONTAINER_ID);

  if (formContainer) {
    const jobListButtonSettings =
      window.OMJobListOptions && window.OMJobListOptions.jobListButtonSettings;

    const buttonElement = formContainer.querySelector(
      "[data-form-jobListButton]"
    );

    if (buttonElement && jobListButtonSettings) {
      const { link, text } = jobListButtonSettings;

      const buttonLabel = text || "検索";
      const buttonLink = link || "#";

      buttonElement.setAttribute("type", "submit");
      buttonElement.textContent = buttonLabel;

      buttonElement.addEventListener(
        "click",
        function (e) {
          const paramObj = getFormValue();
          const queryString = new URLSearchParams(paramObj).toString();
          window.location.href = `${buttonLink}?${queryString}`;
        },
        false
      );
    }
  }
}
