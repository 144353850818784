import { PREFECTURES, FORM_CONTAINER_ID } from "@constants/index";

export default function createPrefectureSelect() {
  const formContainer = document.getElementById(FORM_CONTAINER_ID);

  if (formContainer) {
    const prefSelect = formContainer.querySelector("[data-form-prefecture]");

    const hasDefaultText =
      window.OMJobListOptions &&
      window.OMJobListOptions.prefectureSettings &&
      window.OMJobListOptions.prefectureSettings.defaultText;

    const defaultText = hasDefaultText
      ? window.OMJobListOptions.prefectureSettings.defaultText
      : "すべて";

    const optionsFragment = document.createDocumentFragment();
    if (prefSelect) {
      [[-1, defaultText], ...PREFECTURES].forEach((pref) => {
        const [number, label] = pref;

        const newOption = document.createElement("option");
        const prefLabel = document.createTextNode(label);

        newOption.setAttribute("value", number);
        newOption.appendChild(prefLabel);

        optionsFragment.appendChild(newOption);
      });
      prefSelect.appendChild(optionsFragment);
      prefSelect.value = "";
    }
  }
}
