import doGetRequest from "@modules/doGetRequest";
import { getURLParams } from "@utils/index";
import toggleLoader from "@modules/toggleLoader";

import createUsername from "@ElementMaker/entry/createUsername";
import createJobLink from "@ElementMaker/entry/createJobLink";
import createPhoneInput from "@ElementMaker/entry/createPhoneInput";
import createNameInput from "@ElementMaker/entry/createNameInput";
import createKanaNameInput from "@ElementMaker/entry/createKanaNameInput";
import createGenderInput from "@ElementMaker/entry/createGenderInput";
import createEmailInput from "@ElementMaker/entry/createEmailInput";
import createBirthDateInput from "@ElementMaker/entry/createBirthDateInput";
import createEntryButton from "@ElementMaker/entry/createEntryButton";
import createQuestions from "@ElementMaker/entry/createQuestions";

export default function JobEntry(defaultProps) {
  const { options } = defaultProps || {};

  const urlParams = getURLParams();
  const jobOfferId = urlParams?.id;

  window.OMJobApplyOptions = options || {};

  if (!jobOfferId) {
    console.error("求人番号を設定してください。");
    toggleLoader("[data-job-loader]", false);
    return;
  }
  toggleLoader("[data-job-loader]", true);

  doGetRequest({
    path: `/applicant_api/apply/${jobOfferId}/`,
    successFunc: (res) => {
      const data = res.data;
      const {
        username,
        questions_json: questionJson,
        item_name: itemName,
        job_offer_path: jobOfferPath,
      } = data;

      window.OMJobApplyInfo = {
        jobOfferId,
        username,
        questionJson,
        itemName,
        jobOfferPath,
      };

      createJobLink();
      createUsername();

      createPhoneInput();
      createNameInput();
      createKanaNameInput();
      createGenderInput();
      createEmailInput();
      createBirthDateInput();
      createQuestions();
      createEntryButton();
    },
    errorFunc: (err) => {
      console.error(err);
    },
    finallyFunc: () => {
      toggleLoader("[data-job-loader]", false);
    },
  });
}
