import JobList from "./pages/JobList/index";
import JobDetail from "./pages/JobDetail/index";
import JobLP from "./pages/JobLP/index";
import JobEntry from "./pages/JobEntry/index";
import {
  getURLParams,
  getURLParamString,
  passParamToLinks,
} from "./utils/index";

window.OMTag3 = {
  JobList,
  JobDetail,
  JobLP,
  JobEntry,
  utils: {
    getURLParams,
    getURLParamString,
    passParamToLinks,
  },
};
