import doGetRequest from "@modules/doGetRequest";
import {
  SCRIPT_ID,
  RESULT_CONTAINER_ID,
  PAGER_CONTAINER_ID,
  PAGER_BOTTOM_CONTAINER_ID,
  CONTAINER_ID,
  TAG_KEY,
  WORK_JOB_VERSION,
} from "@constants/index";

import QueryString from "@modules/QueryString";

import createPrefectureSelect from "@ElementMaker/job/createPrefectureSelect";
import createPrefectureInput from "@ElementMaker/job/createPrefectureInput";
import createHiringSystemInput from "@ElementMaker/job/createHiringSystemInput";
import createQueryInput from "@ElementMaker/job/createQueryInput";
import createJobListSubmit from "@ElementMaker/job/createJobListSubmit";
import createJobListButton from "@ElementMaker/job/createJobListButton";
import createJobTypeCountSelect from "@ElementMaker/job/createJobTypeCountSelect";

import createPrefectureCountSelect from "@ElementMaker/job/createPrefectureCountSelect";
import createPrefectureCountInput from "@ElementMaker/job/createPrefectureCountInput";
import createHiringSystemCountInput from "@ElementMaker/job/createHiringSystemCountInput";
import createWorkTypeCountSelect from "@ElementMaker/job/createWorkTypeCountSelect";

import { changePrefSelect } from "@handlers/job/list/change";

import getJobListElement from "./modules/getJobListElement";
import setPager from "./modules/setPager";
import scrollToListHead from "./modules/scrollToListHead";
import registerTemplate from "./modules/registerTemplate";
import setInitialFormValue from "./modules/setInitialFormValue/index";
import getQueryString from "@modules/getQueryString";
import toggleLoader from "@modules/toggleLoader";

export default function JobList(defaultProps) {
  const { params, defaultParams, options, is_filter } = defaultProps || {};
  window.OMJobListDefaultParams =
    window.OMJobListDefaultParams || defaultParams;
  window.OMJobListOptions = window.OMJobListOptions || options;

  if (!is_filter) {
    // Render Form
    const prefInputMode =
      options &&
      options.prefectureSettings &&
      options.prefectureSettings.inputMode;

    if (prefInputMode === "checkbox") {
      createPrefectureInput();
    } else {
      createPrefectureSelect();
    }

    createHiringSystemInput();
    createQueryInput();
    createJobListSubmit({
      searchButtonText: options.searchButtonText || "検索",
    });
    createJobListButton();

    registerTemplate();
  }

  let paramsObj = params || {};
  if (options && options.allowPageReload) {
    paramsObj = getQueryString();
  }

  const scriptElement = document.getElementById(SCRIPT_ID);
  const site_url = scriptElement.dataset.omSite_url;
  const site_key = scriptElement.dataset.omSite_key;
  const all = scriptElement.dataset.omAll === "true";

  if (all) {
    console.log("全件表示モード");
  } else {
    if (!site_key) {
      console.log("site_keyが設定されていません");

      if (!site_url) {
        console.log("site_urlが設定されていません");
        return;
      }
    }
  }

  const queryStringParams = {
    all,
    site_url,
    site_key: site_key || "",
    tag_key: TAG_KEY,
    p: 0,
    ...window.OMJobListDefaultParams,
    ...paramsObj,
  };

  const queryString = QueryString.serializer(queryStringParams);

  if (!!window.OMJobListDefaultParams && !is_filter) {
    const { prefecture } = window.OMJobListDefaultParams;

    if (prefecture != undefined) {
      changePrefSelect(prefecture, queryString);
    }
  }

  toggleLoader("[data-jobs-loader]", true);

  doGetRequest({
    path: `/api/v1/tag/jobs/?${queryString}`,
    successFunc: (res) => {
      const {
        job_offer_list,
        meta: {
          all_job_offers_num,
          count_summaries,
          all_page_num: allPageNum,
          display_num,
          page,
        },
      } = res.data;

      (function renderFetchedForm() {
        const {
          prefecture_data,
          hiring_system_data: hiringSystemData,
          job_type_data: jobTypeData,
          work_type_data: workTypeData,
          job_type2_data: jobType2Data,
          work_type2_data: workType2Data,
        } = count_summaries;

        if (!is_filter) {
          const prefInputMode =
            window.OMJobListOptions &&
            window.OMJobListOptions.prefectureSettings &&
            window.OMJobListOptions.prefectureSettings.inputMode;

          if (prefInputMode === "checkbox") {
            createPrefectureCountInput(prefecture_data, queryString);
          } else {
            createPrefectureCountSelect(prefecture_data, queryString);
          }

          createHiringSystemCountInput(hiringSystemData);

          if (WORK_JOB_VERSION === "2") {
            createWorkTypeCountSelect(workType2Data, jobType2Data);
            createJobTypeCountSelect(jobType2Data);
          } else {
            createWorkTypeCountSelect(workTypeData, jobTypeData);
            createJobTypeCountSelect(jobTypeData);
          }
        }
      })();

      (function renderPager() {
        const currentPage = queryStringParams.p;

        const pagerContainer = document.getElementById(PAGER_CONTAINER_ID);
        setPager(allPageNum, pagerContainer, currentPage, "top");

        const pagerBottomContainer = document.getElementById(
          PAGER_BOTTOM_CONTAINER_ID
        );
        setPager(allPageNum, pagerBottomContainer, currentPage, "bottom");
      })();

      (function renderPageResult() {
        const resultContainer = document.getElementById(RESULT_CONTAINER_ID);

        if (resultContainer) {
          const totalSpan = resultContainer.querySelector("[data-jobs-total]");
          const fromSpan = resultContainer.querySelector("[data-jobs-from]");
          const toSpan = resultContainer.querySelector("[data-jobs-to]");

          const totalDisplay = all_job_offers_num;
          const toDisplay = (page + 1) * display_num;

          if (totalSpan) {
            totalSpan.innerText = totalDisplay;
          }
          if (fromSpan) {
            fromSpan.innerText =
              totalDisplay === 0 ? 0 : page * display_num + 1;
          }
          if (toSpan) {
            if (toDisplay > totalDisplay) {
              toSpan.innerText = totalDisplay;
            } else {
              toSpan.innerText = (page + 1) * display_num;
            }
          }
        }
      })();

      (function renderJobList() {
        const jobElements = job_offer_list.map((job) => {
          return getJobListElement(job);
        });

        const jobContainer = document.getElementById(CONTAINER_ID);

        if (jobElements.length > 0) {
          jobContainer.innerHTML = "";
          jobElements.forEach((jobElement) => {
            jobContainer.appendChild(jobElement);
          });
        } else {
          if (window.OMJobListOptions.emptyLabel) {
            jobContainer.innerHTML = `<span class="${window.OMJobListOptions.emptyLabel.className}">
              ${window.OMJobListOptions.emptyLabel.text}
            </span>`;
          } else {
            jobContainer.innerHTML = `<span class="om-jobs-empty-label">求人が見つかりませんでした。</span>`;
          }
        }
      })();
    },
    errorFunc: (err) => {
      console.error(err);
    },
    finallyFunc: () => {
      toggleLoader("[data-jobs-loader]", false);

      const { isPageScroll, scrollTargetID } = window.OMJobListOptions;

      if (is_filter && isPageScroll) {
        scrollToListHead(scrollTargetID);
      }

      if (options && options.allowPageReload) {
        setInitialFormValue(queryStringParams);
      }
    },
  });
}
