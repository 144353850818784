import { FORM_CONTAINER_ID } from "@constants/index";

export default function createCountCitySelect(city_data) {
  const formContainer = document.getElementById(FORM_CONTAINER_ID);

  if (formContainer) {
    const citySelect = formContainer.querySelector("[data-form-count-city]");

    const hasDefaultText =
      window.OMJobListOptions &&
      window.OMJobListOptions.citySettings &&
      window.OMJobListOptions.citySettings.defaultText;

    const defaultText = hasDefaultText
      ? window.OMJobListOptions.citySettings.defaultText
      : "すべて";

    const optionsFragment = document.createDocumentFragment();
    if (citySelect) {
      citySelect.innerHTML = `<option value="">${defaultText}</option>`;
      city_data.forEach((city) => {
        const { label, count } = city;

        const hasCountBracket =
          window.OMJobListOptions && window.OMJobListOptions.prefectureSettings
            ? window.OMJobListOptions.prefectureSettings.hasCountBracket
            : false;

        const isShowNoCount =
          window.OMJobListOptions && window.OMJobListOptions.prefectureSettings
            ? window.OMJobListOptions.prefectureSettings.isShowNoCount
            : false;

        let labelText = `${label}`;
        let isSkipNoCount = false;
        if (
          !!window.OMJobListOptions &&
          !!window.OMJobListOptions.prefectureSettings
        ) {
          if (hasCountBracket) {
            labelText = `${label}(${count})`;
          }

          isSkipNoCount = !isShowNoCount;
        }

        const isNoCount = count == 0;
        const newOption = document.createElement("option");
        const cityLabel = document.createTextNode(labelText);

        if (isSkipNoCount && isNoCount) {
          return;
        }

        newOption.setAttribute("value", label);
        newOption.disabled = isNoCount;
        newOption.setAttribute("aria-disabled", isNoCount ? "true" : "false");
        newOption.appendChild(cityLabel);

        optionsFragment.appendChild(newOption);
      });

      citySelect.appendChild(optionsFragment);
    }
  }
}
