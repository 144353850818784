class Checkbox {
  constructor(name, inputContainer, options) {
    this.name = name;
    this.inputContainer = inputContainer;
    this.options = options;
  }

  create() {
    if (this.inputContainer) {
      const optionsFragment = document.createDocumentFragment();
      this.options.forEach((item) => {
        const [value, label] = item;
        const itemLabel = document.createElement("label");

        const input = document.createElement("input");
        input.setAttribute("name", this.name);
        input.setAttribute("type", "checkbox");
        input.setAttribute("value", value);
        const text = document.createTextNode(label);

        itemLabel.appendChild(input);
        itemLabel.appendChild(text);

        optionsFragment.appendChild(itemLabel);
      });

      this.inputContainer.appendChild(optionsFragment);
    }
  }
}

export default Checkbox;
