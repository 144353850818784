class Text {
  constructor(name, inputContainer, placeholder) {
    this.name = name;
    this.inputContainer = inputContainer;
    this.placeholder = placeholder;
  }

  create() {
    if (this.inputContainer) {
      const inputElement = document.createElement("input");
      inputElement.setAttribute("name", this.name);
      inputElement.setAttribute("type", "text");
      inputElement.setAttribute("placeholder", this.placeholder);

      this.inputContainer.appendChild(inputElement);
    }
  }
}

export default Text;
