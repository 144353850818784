import { DOMAIN } from "@constants/index";

export default function setCompanyInfoValue(job, itemList, template) {
  function setCompanyInfoValue(element, isShow, value) {
    if (isShow) {
      element.innerText = value;
    } else {
      element.innerText = "";
      element.setAttribute("aria-label", "非公開設定になっています");
    }
  }
  itemList.forEach((itemLabel) => {
    const items = template.querySelectorAll(`[data-job-company_${itemLabel}]`);

    items.forEach((item) => {
      if (item) {
        switch (itemLabel) {
          case "username":
            setCompanyInfoValue(
              item,
              job["show_company_name"],
              job["company_info"][itemLabel]
            );
            return;
          case "zip_code":
          case "prefecture":
          case "city":
          case "building":
            setCompanyInfoValue(
              item,
              job["show_address"],
              job["company_info"][itemLabel]
            );
            return;
          case "phone":
            setCompanyInfoValue(
              item,
              job["show_phone"],
              job["company_info"][itemLabel]
            );
            return;
          case "recruit_link":
            const siteUrl = job["company_info"]["url"];
            const link = `${DOMAIN}/${siteUrl}/top/`;
            item.setAttribute("href", link);

            if (item.innerText === "") {
              item.innerText = link;
            }
            return;
        }
      }
    });
  });
}
