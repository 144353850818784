import { FORM_CONTAINER_ID } from "@constants/index";

function changeJobTypeSelect(workType, jobTypeData) {
  const formContainer = document.getElementById(FORM_CONTAINER_ID);

  if (formContainer) {
    const jobTypeSelect = formContainer.querySelector("[data-form-jobType]");

    const hasDefaultText =
      window.OMJobListOptions &&
      window.OMJobListOptions.jobTypeSettings &&
      window.OMJobListOptions.jobTypeSettings.defaultText;

    const defaultText = hasDefaultText
      ? window.OMJobListOptions.jobTypeSettings.defaultText
      : "すべて";

    if (jobTypeSelect) {
      jobTypeSelect.innerHTML = "";
      const optionsFragment = document.createDocumentFragment();
      [
        {
          number: "",
          label: defaultText,
          count: 0,
          work_type: -1,
        },
        ...jobTypeData,
      ].forEach((jobType) => {
        const { number, label, count, work_type: targetWorkType } = jobType;
        const { hasCountBracket, isShowNoCount } =
          window.OMJobListOptions && window.OMJobListOptions.workTypeSettings
            ? window.OMJobListOptions.workTypeSettings
            : {};
        const isAllOption = number === "";

        if (!isAllOption && String(targetWorkType) !== String(workType)) {
          return;
        }

        if (!isAllOption && !isShowNoCount && !isAllOption && count == 0) {
          return;
        }

        const labelText =
          !isAllOption && hasCountBracket ? `${label}(${count})` : `${label}`;
        const isSkipNoCount = !isShowNoCount;

        const isNoCount = count == 0;
        const newOption = document.createElement("option");
        const jobTypeLabel = document.createTextNode(labelText);

        if (!isAllOption && isSkipNoCount && isNoCount) {
          return;
        }

        newOption.setAttribute("value", number);
        newOption.disabled = !isAllOption && isNoCount;
        newOption.setAttribute("aria-disabled", isNoCount ? "true" : "false");
        newOption.appendChild(jobTypeLabel);

        optionsFragment.appendChild(newOption);
      });

      jobTypeSelect.appendChild(optionsFragment);
    }
  }
}

export default function createWorkTypeCountSelect(workTypeData, jobTypeData) {
  const formContainer = document.getElementById(FORM_CONTAINER_ID);

  if (formContainer) {
    const worktypeSelect = formContainer.querySelector("[data-form-workType]");

    const hasDefaultText =
      window.OMJobListOptions &&
      window.OMJobListOptions.workTypeSettings &&
      window.OMJobListOptions.workTypeSettings.defaultText;

    const defaultText = hasDefaultText
      ? window.OMJobListOptions.workTypeSettings.defaultText
      : "すべて";

    const optionsFragment = document.createDocumentFragment();
    if (worktypeSelect) {
      worktypeSelect.innerHTML = "";
      [
        {
          number: "",
          label: defaultText,
          count: 99,
        },
        ...workTypeData,
      ].forEach((workType) => {
        const { number, label, count } = workType;
        const { hasCountBracket, isShowNoCount } =
          window.OMJobListOptions && window.OMJobListOptions.workTypeSettings
            ? window.OMJobListOptions.workTypeSettings
            : {};
        const isAllOption = number === "";

        if (!isShowNoCount && count == 0) {
          return;
        }

        const labelText =
          !isAllOption && hasCountBracket ? `${label}(${count})` : `${label}`;
        const isSkipNoCount = !isShowNoCount;

        const isNoCount = count == 0;
        const newOption = document.createElement("option");
        const workTypeLabel = document.createTextNode(labelText);

        if (isSkipNoCount && isNoCount) {
          return;
        }

        newOption.setAttribute("value", number);
        newOption.disabled = !isAllOption && isNoCount;
        newOption.setAttribute("aria-disabled", isNoCount ? "true" : "false");
        newOption.appendChild(workTypeLabel);

        optionsFragment.appendChild(newOption);
      });

      worktypeSelect.appendChild(optionsFragment);
      worktypeSelect.addEventListener("change", function (e) {
        return changeJobTypeSelect(e.target.value, jobTypeData);
      });
    }
  }
}
