export default function formatStringDate(string_date, dateType) {
  const date = new Date(string_date);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();

  switch (dateType) {
    case 0:
      return `${year}/${month}/${day}`;
    case 1:
      return `${year}/${month}/${day} ${hour}:${minute}`;
    case 2:
      return `${year}年${month}月${day}日`;
    case 3:
      return `${year}年${month}月${day}日${hour}時${minute}分`;
    default:
      return `${year}/${month}/${day} ${hour}:${minute}`;
  }
}
