import { FORM_CONTAINER_ID } from "@constants/index";

export default function createJobTypeCountSelect(jobTypeData) {
  const formContainer = document.getElementById(FORM_CONTAINER_ID);

  if (formContainer) {
    const jobTypeSelect = formContainer.querySelector("[data-form-jobType]");

    const hasDefaultText =
      window.OMJobListOptions &&
      window.OMJobListOptions.jobTypeSettings &&
      window.OMJobListOptions.jobTypeSettings.defaultText;

    const defaultText = hasDefaultText
      ? window.OMJobListOptions.jobTypeSettings.defaultText
      : "すべて";

    const optionsFragment = document.createDocumentFragment();
    if (jobTypeSelect) {
      jobTypeSelect.innerHTML = "";
      [
        {
          number: "",
          label: defaultText,
          count: 99,
          work_type: -1,
        },
        ...jobTypeData,
      ].forEach((jobType) => {
        const { number, label, count } = jobType;
        const { hasCountBracket, isShowNoCount } =
          window.OMJobListOptions && window.OMJobListOptions.workTypeSettings
            ? window.OMJobListOptions.workTypeSettings
            : {};
        const isAllOption = number === "";

        if (!isShowNoCount && count == 0) {
          return;
        }

        const labelText =
          !isAllOption && hasCountBracket ? `${label}(${count})` : `${label}`;
        const isSkipNoCount = !isShowNoCount;

        const isNoCount = count == 0;
        const newOption = document.createElement("option");
        const jobTypeLabel = document.createTextNode(labelText);

        if (isSkipNoCount && isNoCount) {
          return;
        }

        newOption.setAttribute("value", number);
        newOption.disabled = !isAllOption && isNoCount;
        newOption.setAttribute("aria-disabled", isNoCount ? "true" : "false");
        newOption.appendChild(jobTypeLabel);

        optionsFragment.appendChild(newOption);
      });

      jobTypeSelect.appendChild(optionsFragment);
    }
  }
}
