import { ENTRY_FIELDS } from "@constants/index";

function getBirthDate(year, month, date) {
  const month0 = Number(month) < 10 ? "0" + month : "" + month;
  const date0 = Number(date) < 10 ? "0" + date : "" + date;

  return `${year}-${month0}-${date0}`;
}

export default function getEntryForms(formContainer) {
  const formData = {};

  ENTRY_FIELDS.filter(
    (field) => !["birth_day", "gender"].includes(field)
  ).forEach((field) => {
    const input = formContainer.querySelector(`[data-form-${field}] > input`);
    if (input) {
      formData[field] = input.value;
    } else {
      console.error("unknown field:", field);
    }
  });

  const yearInput = formContainer.querySelector(`[data-form-year] > select`);
  const monthInput = formContainer.querySelector(`[data-form-month] > select`);
  const dateInput = formContainer.querySelector(`[data-form-date] > select`);

  formData["birth_day"] = getBirthDate(
    yearInput.value,
    monthInput.value,
    dateInput.value
  );

  const selectedGenderElement = document.querySelector(
    '[data-form-gender] input[name="gender"]:checked'
  );
  if (selectedGenderElement) {
    formData["gender"] = selectedGenderElement.value;
  }

  return formData;
}
