export default function setInitialBeginnerCheck(beginnerCheck) {
  if (beginnerCheck === undefined || beginnerCheck === null) {
    return;
  }

  const selector = "[data-form-beginnercheck]";
  const element = document.querySelector(selector);

  if (element) {
    element.checked = beginnerCheck === "1";
  }
}
