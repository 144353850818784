import { APPLY_FORM_CONTAINER_ID } from "@constants/index";

const GENDERS = [
  [0, "男性"],
  [1, "女性"],
  [2, "その他"],
];

const DEFAULT_GENDER = 0;
export default function createGenderInput() {
  const formContainer = document.getElementById(APPLY_FORM_CONTAINER_ID);

  if (formContainer) {
    const inputContainer = formContainer.querySelector(`[data-form-gender]`);

    if (inputContainer) {
      GENDERS.forEach((genderRatio) => {
        const [value, label] = genderRatio;

        const labelElement = document.createElement("label");

        const newInput = document.createElement("input");
        newInput.setAttribute("type", "radio");
        newInput.setAttribute("name", "gender");
        newInput.setAttribute("value", value);
        newInput.checked = value === DEFAULT_GENDER;

        const labelText = document.createTextNode(label);

        labelElement.appendChild(newInput);
        labelElement.appendChild(labelText);

        inputContainer.appendChild(labelElement);
      });
    }
  }
}
