import formatStringDate from "@formatters/formatStringDate";
import formatSalary from "@formatters/formatSalary";
import formatJobLink from "@formatters/formatJobLink";
import hasNGElement from "@modules/hasNGElement";
import { getLinkSettings } from "@utils/index";

import { DUMMY_IMAGE_URL, HIRING_SYSTEM } from "@constants/index";
import getCustomJobListElement from "./getCustomJobListElement";

const HIRING_SYSTEM_MAP = {};
HIRING_SYSTEM.forEach((item) => {
  const [value, label] = item;
  HIRING_SYSTEM_MAP[label] = value;
});

export default function getJobListElement(job) {
  const template = window.OMJobListTemplate;
  if (template) {
    if (hasNGElement(template)) {
      console.error("無効なタグが含まれています。script, link, meta");
    } else {
      const tempTemplate = template.cloneNode(true);

      let item = null;
      try {
        [
          "image1_caption",
          "item_name",
          "work_type",
          "job_type",
          "work_type2",
          "job_type2",
          "hiring_system",
          "salary_pattern",
          "prefecture",
          "city",
          "building",
        ].forEach((item_label) => {
          item = tempTemplate.querySelector(`[data-job-${item_label}]`);
          if (item) {
            item.innerText = job[item_label];
          }
        });

        if (
          job["hiring_system"] &&
          HIRING_SYSTEM_MAP[job["hiring_system"]] !== undefined
        ) {
          tempTemplate.setAttribute(
            "data-job-container-hiring_system",
            HIRING_SYSTEM_MAP[job["hiring_system"]]
          );
        }

        item = tempTemplate.querySelector("[data-job-company_name]");
        if (item) {
          let company = job.company.user.username;
          item.textContent = company;
        }

        item = tempTemplate.querySelector("[data-job-work_place]");
        if (item) {
          let work_place = job.company_name;
          item.textContent = work_place;
        }

        item = tempTemplate.querySelector("[data-job-job_description]");
        if (item) {
          let job_description = job["job_description"];

          if (
            window.OMJobListOptions &&
            window.OMJobListOptions.descriptionChar
          ) {
            const descriptionChar = window.OMJobListOptions.descriptionChar;

            if (job_description.length > descriptionChar) {
              job_description =
                job_description.slice(0, descriptionChar) + "...";
            }
          }
          item.textContent = job_description;
        }

        item = tempTemplate.querySelector("[data-job-image1]");
        if (item) {
          if (!!job.image1) {
            item.setAttribute("src", job.image1);
          } else {
            const hasCustomImage =
              window.OMJobListOptions &&
              window.OMJobListOptions.customImageUrl &&
              window.OMJobListOptions.customImageUrl !== "";
            const dummyImageUrl = hasCustomImage
              ? window.OMJobListOptions.customImageUrl
              : DUMMY_IMAGE_URL;

            item.setAttribute("src", dummyImageUrl);
          }
        }

        const items = tempTemplate.querySelectorAll("[data-job-link]");
        items.forEach((item) => {
          if (item) {
            let href = formatJobLink(job);
            if (window.OMJobListOptions && window.OMJobListOptions.getJobLink) {
              const getJobLink = window.OMJobListOptions.getJobLink;
              href = getJobLink(job);
            }
            const linkSettings =
              window.OMJobListOptions && window.OMJobListOptions.linkSettings
                ? window.OMJobListOptions.linkSettings
                : {};

            const paramString = getLinkSettings(linkSettings);
            const paramSign = href.includes("?") ? "&" : "?";

            item.setAttribute("href", `${href}${paramSign}${paramString}`);
          }
        });

        item = tempTemplate.querySelector("[data-job-salary_min]");
        if (item) {
          item.innerText = formatSalary(job.salary_min);
        }

        item = tempTemplate.querySelector("[data-job-salary_max]");
        if (item) {
          item.innerText = formatSalary(job.salary_max);

          if (!!job.salary_max) {
            var salaryWavy = tempTemplate.querySelector(
              "[data-job-salary_wavy]"
            );
            if (salaryWavy) {
              salaryWavy.textContent = "～";
            }
          }
        }

        item = tempTemplate.querySelector("[data-job-created_at]");
        if (item) {
          let dateType = 0;
          if (window.OMJobListOptions && window.OMJobListOptions.dateType) {
            dateType = window.OMJobListOptions.dateType;
          }
          item.innerText = formatStringDate(job.created_at, dateType);
        }

        // 未経験・初心者OK beginner
        getCustomJobListElement(tempTemplate, job);

        return tempTemplate;
      } catch (e) {
        console.error(e);
      }
    }
  } else {
    console.error(
      "#om-jobs-container直下にテンプレートが見つかりませんでした。"
    );
  }
}
