import setInitialValue from "./modules/setInitialValue";
import setInitialHiringSystem from "./modules/setInitialHiringSystem";
import setInitialBeginnerCheck from "./modules/setInitialBeginnerCheck";
import setInitialPrefecture from "./modules/setInitialPrefecture";

export default function setInitialFormValue(params) {
  const {
    city,
    query,
    prefecture,
    work_type: workType,
    job_type: jobType,
    hiring_system: hiringSystem,
    is_beginner_check: beginnerCheck,
  } = params;

  setInitialValue("[data-form-workType]", workType);
  setInitialValue("[data-form-jobType]", jobType);
  setInitialValue("[data-form-count-city]", city);
  setInitialValue("[data-form-query] input,input[data-form-query]", query);

  setInitialPrefecture(prefecture);
  setInitialHiringSystem(hiringSystem);
  setInitialBeginnerCheck(beginnerCheck);
}
