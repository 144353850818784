import { DOMAIN } from "@constants/index";

export default function formatJobLink(job) {
  const {
    id,
    company: { site_url: siteUrl },
    prefecture_number: prefectureNumber,
    work_type_number: workTypeNumber,
    hiring_system_number: hiringSystemNumber,
  } = job;

  return `${DOMAIN}/${siteUrl}/jobs/${id}/?prefecture=${prefectureNumber}&work_type=${workTypeNumber}&hiring_system=${hiringSystemNumber}`;
}
