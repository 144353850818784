import { HIRING_SYSTEM, FORM_CONTAINER_ID } from "@constants/index";

export default function createHiringSystemInput() {
  const formContainer = document.getElementById(FORM_CONTAINER_ID);

  if (formContainer) {
    const hiringSystemInputs = formContainer.querySelectorAll(
      "[data-form-hiringSystem]"
    );

    hiringSystemInputs.forEach((hiringSystemInput) => {
      let labelTemplate = null;
      if (hiringSystemInput.children.length > 0) {
        labelTemplate = hiringSystemInput.children[0];
      }

      hiringSystemInput.innerHTML = "";

      const labelsFragment = document.createDocumentFragment();

      const targetHiringSystems =
        window.OMJobListDefaultParams &&
        window.OMJobListDefaultParams.target_hiring_systems;

      let hiringSystems = HIRING_SYSTEM;
      // 表示させる雇用形態の絞り込み
      if (targetHiringSystems) {
        const targetHSs = targetHiringSystems.split(",");
        hiringSystems = HIRING_SYSTEM.filter((hiringSystem) => {
          const [number, _] = hiringSystem;
          return targetHSs.includes(String(number));
        });
      }

      hiringSystems.forEach((hiringSystem) => {
        const [number, label] = hiringSystem;

        if (labelTemplate) {
          const newContainerLabel = labelTemplate.cloneNode(true);

          const newInput = newContainerLabel.querySelector("input");
          newInput.setAttribute("name", "om-forms-hiringSystem");
          newInput.setAttribute("type", "checkbox");
          newInput.setAttribute("value", number);

          const newSpan = newContainerLabel.querySelector("span");
          newSpan.textContent = label;

          labelsFragment.appendChild(newContainerLabel);
        } else {
          const newInput = document.createElement("input");
          newInput.setAttribute("name", "om-forms-hiringSystem");
          newInput.setAttribute("type", "checkbox");
          newInput.setAttribute("value", number);

          const newSpan = document.createElement("span");
          const hiringSystemText = document.createTextNode(label);
          newSpan.appendChild(hiringSystemText);

          const newLabel = document.createElement("label");
          newLabel.appendChild(newInput);
          newLabel.appendChild(newSpan);

          labelsFragment.appendChild(newLabel);
        }
      });

      hiringSystemInput.appendChild(labelsFragment);
    });
  }
}
