// import validateEntryForm from "./validateEntryForm";
import EntryError from "./EntryError";

class QuestionError extends EntryError {
  validateForm(formData) {
    // override
    this.QUESTION_FIELDS = formData.map((data) => data.uuid);
    let success = true;
    let entryErrors = {};
    formData.forEach((data) => {
      const { uuid, is_required: isRequired, answer } = data;

      entryErrors[uuid] = [];
      if (isRequired) {
        if (answer === undefined || answer === null || answer.length === 0) {
          entryErrors[uuid] = [`必須項目です`];
          success = false;
        }
      }
    });

    // {[key: string]: string[]}
    return { success, entryErrors };
  }

  getErrors() {
    //override
    this.QUESTION_FIELDS.forEach((field) => {
      // string[]
      const fieldErrors = this.errors[field];
      this.setText(field, fieldErrors);
    });
  }
}

export default QuestionError;
