export default function setInitialHiringSystem(hiringSystem) {
  if (!hiringSystem) {
    return;
  }

  const hiringSystems = hiringSystem.split(",");
  const inputs = document.getElementsByName("om-forms-hiringSystem");

  for (let i = 0; i < inputs.length; i++) {
    const input = inputs[i];
    if (hiringSystems.includes(input.value)) {
      input.checked = true;
    }
  }
}
