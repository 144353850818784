import { clickJobPage } from "@handlers/job/list/click";
import hasNGElement from "@modules/hasNGElement";

export default function getPagerElement(pagerTemplate, page, label, pagerType) {
  if (pagerTemplate) {
    if (hasNGElement(pagerTemplate)) {
      console.error("無効なタグが含まれています。script, link, meta");
    } else {
      const tempTemplate = pagerTemplate.cloneNode(true);

      tempTemplate.innerText = label;
      tempTemplate.dataset.jobsPage = page;
      tempTemplate.dataset.pagerType = pagerType;
      tempTemplate.addEventListener("click", clickJobPage, false);

      return tempTemplate;
    }
  }
}
