import setJobValue from "./setJobValue";
import setCompanyInfoValue from "./setCompanyInfoValue";
import setJobNumberValue from "./setJobNumberValue";
import hasNGElement from "@modules/hasNGElement";

import formatStringDate from "@formatters/formatStringDate";
import formatSalary from "@formatters/formatSalary";
import formatApplyLink from "@formatters/formatApplyLink";
import { getLinkSettings } from "@utils/index";

import {
  JOB_DETAIL_TEXT_ITEMS,
  JOB_DETAIL_INT_ITEMS,
  JOB_DATE_ITEMS,
  JOB_MULTI_FIELDS,
  JOB_IMAGE_FIELDS,
  JOB_LINK_FIELDS,
  COMPANY_INFO_FIELDS,
} from "@constants/index";

import {
  ContainerHandler,
  MultiChoiceContainerHandler,
  FeatureContainerHandler,
  TrialContainerHandler,
  TravelCostContainerHandler,
  JobNumberContainerHandler,
  FixedOvertimePaySystemContainerHandler,
  CompanyInfoContainerHandler,
  TrialWorkTimeContainerHandler,
} from "./ContainerHandlers";

function isNumeric(value) {
  return !isNaN(value) && !isNaN(parseInt(value));
}

export default function getJobElement(template, job) {
  if (template) {
    if (hasNGElement(template)) {
      console.error("無効なタグが含まれています。script, link, meta");
    } else {
      const tempTemplate = template.cloneNode(true);
      let items = null;
      try {
        if (window.OMJobDetailOptions?.getTitleText) {
          const getTitleText = window.OMJobDetailOptions.getTitleText;
          const title = getTitleText(job);
          document.title = title;
        }
        // 各要素にデータを挿入
        setJobValue(job, JOB_DETAIL_TEXT_ITEMS, null, tempTemplate);

        setJobValue(
          job,
          ["environment_gender_ratio"],
          function (value) {
            switch (value) {
              case "-1":
              case null:
                return "";
              default:
                return value;
            }
          },
          tempTemplate
        );

        setJobValue(
          job,
          JOB_DETAIL_INT_ITEMS,
          (rawValue) => formatSalary(rawValue, 0),
          tempTemplate
        );

        let dateType = 0;
        if (isNumeric(window.OMJobDetailOptions?.dateType)) {
          dateType = window.OMJobDetailOptions.dateType;
        }

        setJobValue(
          job,
          JOB_DATE_ITEMS,
          (rawValue) => formatStringDate(rawValue, dateType),
          tempTemplate
        );

        // 非表示の場合、HTMLコードにも非表示にする値の設定
        setCompanyInfoValue(job, COMPANY_INFO_FIELDS, tempTemplate);

        setJobNumberValue(job, tempTemplate);

        JOB_MULTI_FIELDS.forEach((itemLabel) => {
          items = tempTemplate.querySelectorAll(`[data-job-${itemLabel}]`);
          if (items) {
            items.forEach((item) => {
              if (item) {
                const multiItems = job[itemLabel];

                if (multiItems) {
                  multiItems.forEach((multi_item) => {
                    item.innerHTML += `<span class="om-job-label om-job-label-${itemLabel}">${multi_item}</span>`;
                  });
                }

                if (multiItems && multiItems.length === 0) {
                  item.style = "display: none;";
                }
              }
            });
          }
        });

        JOB_IMAGE_FIELDS.forEach((itemLabel) => {
          items = tempTemplate.querySelectorAll(`[data-job-${itemLabel}]`);
          if (items) {
            items.forEach((item) => {
              if (item) {
                item.setAttribute("src", job[itemLabel]);
                item.setAttribute("alt", job[`${itemLabel}_caption`]);
              }
            });
          }
        });

        ["embed_url"].forEach((itemLabel) => {
          items = tempTemplate.querySelectorAll(`[data-job-${itemLabel}]`);
          if (items) {
            items.forEach((item) => {
              if (item) {
                item.setAttribute("src", job[itemLabel]);
              }
            });
          }
        });

        // 応募ボタンのリンクを設定
        const getCustomEntryLink =
          window.OMJobDetailOptions?.getCustomEntryLink;

        items = tempTemplate.querySelectorAll(`[data-job-apply]`);
        if (items) {
          items.forEach((item) => {
            if (item) {
              let link = formatApplyLink(job);
              if (getCustomEntryLink) {
                link = getCustomEntryLink(job);
              }

              const linkSettings =
                window.OMJobDetailOptions &&
                window.OMJobDetailOptions.linkSettings
                  ? window.OMJobDetailOptions.linkSettings
                  : {};

              const paramString = getLinkSettings(linkSettings);
              const paramSign = link.includes("?") ? "&" : "?";

              item.setAttribute("href", `${link}${paramSign}${paramString}`);
            }
          });
        }

        // urlのリンクを設定
        JOB_LINK_FIELDS.forEach((itemLabel) => {
          const item = tempTemplate.querySelector(`[data-job-${itemLabel}]`);
          if (item) {
            const url = job[itemLabel];
            item.setAttribute("href", url);
            if (item.innerText === "") {
              item.innerText = url;
            }
          }
        });

        // 値によって非表示にする処理
        for (const label in job) {
          const containerHandler = JOB_MULTI_FIELDS.includes(label)
            ? new MultiChoiceContainerHandler(job, label, tempTemplate)
            : new ContainerHandler(job, label, tempTemplate);

          if (containerHandler) containerHandler.switchContainers();
        }

        [
          "feature",
          "trial",
          "travel_cost",
          "job_number",
          "fixed_overtime_pay_system",
          "trial_work_time",

          "company_username",
          "company_address",
          "company_phone",
        ].forEach((label) => {
          const CONTAINER_MAP = {
            feature: FeatureContainerHandler,
            trial: TrialContainerHandler,
            travel_cost: TravelCostContainerHandler,
            job_number: JobNumberContainerHandler,
            fixed_overtime_pay_system: FixedOvertimePaySystemContainerHandler,
            trial_work_time: TrialWorkTimeContainerHandler,

            company_username: CompanyInfoContainerHandler,
            company_address: CompanyInfoContainerHandler,
            company_phone: CompanyInfoContainerHandler,
          };

          const containerHandler = new CONTAINER_MAP[label](
            job,
            label,
            tempTemplate
          );
          if (containerHandler) containerHandler.switchContainers();
        });

        return tempTemplate;
      } catch (e) {
        console.error(e);
      }
    }
  } else {
    console.error(
      "#om-jobs-container直下にテンプレートが見つかりませんでした。"
    );
  }
}
