export default function getCustomJobListElement(template, job) {
  const item = template.querySelector("[data-job-beginner]");
  if (item && job.workstyle_experience) {
    item.innerHTML = "";
    job.workstyle_experience
      .filter((multi_item) => multi_item.choice === "未経験・初心者OK")
      .forEach((multi_item) => {
        const labelSpan = document.createElement("span");
        labelSpan.setAttribute("class", "om-job-label om-job-label-beginner");
        labelSpan.innerText = multi_item.choice;

        item.appendChild(labelSpan);
      });
  }
}
