export default function setJobNumberValue(job, template) {
  const item = template.querySelector(`[data-job-job_number]`);

  if (item) {
    const isShow = job["show_job_number"];
    if (isShow) {
      item.innerText = job["job_number"];
    } else {
      item.innerText = "";
      item.setAttribute("aria-label", "非公開設定になっています");
    }
    return;
  }
}
