import { APPLY_FORM_CONTAINER_ID } from "@constants/index";
import { DOMAIN } from "../../constants";

// data-job-job_link
export default function createUsername() {
  const formContainer = document.getElementById(APPLY_FORM_CONTAINER_ID);

  if (window.OMJobApplyInfo && formContainer) {
    const { username } = window.OMJobApplyInfo;

    const usernames = formContainer.querySelectorAll("[data-job-username]");

    usernames.forEach((usernameElement) => {
      const text = document.createTextNode(username);
      usernameElement.appendChild(text);
    });
  }
}
