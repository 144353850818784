import { APPLY_FORM_CONTAINER_ID } from "@constants/index";
import Text from "@ElementMaker/basic/Text";

export default function createPhoneInput() {
  const formContainer = document.getElementById(APPLY_FORM_CONTAINER_ID);

  if (formContainer) {
    const phoneContainer = formContainer.querySelector("[data-form-phone]");
    if (phoneContainer) {
      new Text("phone", phoneContainer, "電話番号を入力").create();
    }
  }
}
