import { APPLY_FORM_CONTAINER_ID } from "@constants/index";
import Text from "@ElementMaker/basic/Text";
import Select from "@ElementMaker/basic/Select";
import Checkbox from "@ElementMaker/basic/Checkbox";
import Textarea from "@ElementMaker/basic/Textarea";
import { isDictEmpty } from "@utils/isDictEmpty";

export default function createQuestions() {
  const formContainer = document.getElementById(APPLY_FORM_CONTAINER_ID);

  if (formContainer) {
    const questionContainer = formContainer.querySelector(
      `[data-form-questions]`
    );

    const { questionJson } = window.OMJobApplyInfo;

    if (isDictEmpty(questionJson)) {
      const questionOuterContainer = formContainer.querySelector(
        `[data-form-container-questions]`
      );

      questionOuterContainer.setAttribute("style", "display: none;");
    }

    if (questionContainer && questionJson) {
      const dlElement = document.createElement("dl");

      questionJson.forEach((question) => {
        const { type, label, items, uuid, is_required: isRequired } = question;
        const dtElement = document.createElement("dt");
        const ddElement = document.createElement("dd");
        const errorElement = document.createElement("dd");

        const options = items.map((item) => [item.label, item.label]);

        dtElement.appendChild(document.createTextNode(label));
        if (isRequired) {
          const spanElement = document.createElement("span");
          const requiredText = document.createTextNode("必須");
          spanElement.appendChild(requiredText);
          dtElement.appendChild(spanElement);
        }

        const itemContainer = document.createElement("div");
        itemContainer.setAttribute("class", "om-question-item");
        switch (type) {
          case "text":
            new Text(uuid, itemContainer, label).create();
            break;
          case "textarea":
            new Textarea(uuid, itemContainer, label).create();
            break;
          case "select":
            new Select(uuid, itemContainer, options, null).create();
            break;
          case "checkbox":
            new Checkbox(uuid, itemContainer, options).create();
            break;
          default:
            break;
        }

        errorElement.setAttribute(`data-form-error-${uuid}`, "");
        errorElement.setAttribute("class", `data-form-error-${uuid}`);

        ddElement.appendChild(itemContainer);

        dlElement.appendChild(dtElement);
        dlElement.appendChild(ddElement);
        dlElement.appendChild(errorElement);

        if (itemContainer) {
          questionContainer.appendChild(dlElement);
        }
      });
    }
  }
}
