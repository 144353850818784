import { getURLParams } from "@utils/index";

export default function JobLP(defaultProps) {
  const { options } = defaultProps;

  const isPassLinkParams = options.isPassLinkParams;

  if (isPassLinkParams) {
    const items = document.querySelectorAll(`[data-lp-job_list_link]`);
    items.forEach((item) => {
      if (item) {
        const originalUrl = item.getAttribute("href");
        const params = getURLParams();
        const paramString = Object.keys(params)
          .map(function (key) {
            return (
              encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
            );
          })
          .join("&");

        item.setAttribute("href", `${originalUrl}?${paramString}`);
      }
    });
  }
}
