import { ENTRY_FIELDS } from "@constants/index";
import { z } from "zod";

class EntryError {
  constructor(formContainer, formData) {
    this.formContainer = formContainer;
    this.BASIC_FIELDS = ["phone", "gender", "email", "birth_day"];
    this.NAME_FIELDS = ["name", "name_kana"];
    // {[key: string]: string[]}
    const { success, entryErrors } = this.validateForm(formData);

    this.errors = entryErrors;
    this.success = success;
  }

  validateForm(formData) {
    const ENTRY_SCHEME_MAP = {
      first_name: z.string().min(1, { message: "必須項目です" }),
      last_name: z.string().min(1, { message: "必須項目です" }),
      first_name_kana: z.string().min(1, { message: "必須項目です" }),
      last_name_kana: z.string().min(1, { message: "必須項目です" }),
      phone: z
        .string()
        .min(1, { message: "必須項目です" })
        .refine(
          (value) => /^0\d{9,10}$/.test(value),
          "正しい電話番号形式、ハイフンなしで入力してください"
        ),
      gender: z.string().refine((value) => value !== null, {
        message: "値はnullではありません。",
      }),
      email: z
        .string()
        .min(1, { message: "必須項目です" })
        .email("正しいメール形式を入力してください"),
      birth_day: z.string().min(1, { message: "必須項目です" }),
    };

    const EntryScheme = z.object(ENTRY_SCHEME_MAP);
    let entryErrors = {};

    ENTRY_FIELDS.forEach((field) => {
      entryErrors[field] = [];
    });

    let success = true;
    try {
      EntryScheme.parse(formData);
    } catch (error) {
      if (error instanceof z.ZodError) {
        ENTRY_FIELDS.forEach((field) => {
          const fieldErrors = error.errors.filter(
            (err) => err.path[0] === field
          );
          entryErrors[field] = fieldErrors.map((err) => err.message);
        });
      }
      success = false;
    }

    return { success, entryErrors };
  }

  setText(field, fieldErrors) {
    const container = this.formContainer.querySelector(
      `[data-form-error-${field}]`
    );

    if (container) {
      container.innerHTML = "";
      const fragment = document.createDocumentFragment();

      if (fieldErrors.length > 0) {
        fieldErrors.slice(0, 1).forEach((error) => {
          const span = document.createElement("span");
          const text = document.createTextNode(error);
          span.appendChild(text);
          fragment.appendChild(span);
        });
      }

      container.appendChild(fragment);
    }
  }

  getErrors() {
    this.BASIC_FIELDS.forEach((field) => {
      // string[]
      const fieldErrors = this.errors[field];
      this.setText(field, fieldErrors);
    });

    this.NAME_FIELDS.forEach((field) => {
      const firstErrors = this.errors[`first_${field}`];
      const lastErrors = this.errors[`last_${field}`];

      // string[]
      const nameErrors = Array.from(new Set([...firstErrors, ...lastErrors]));

      this.setText(field, nameErrors);
    });
  }
}

export default EntryError;
