import {
  PAGER_CONTAINER_ID,
  PAGER_BOTTOM_CONTAINER_ID,
  CONTAINER_ID,
} from "@constants/index";

export default function registerTemplate() {
  const container = document.getElementById(CONTAINER_ID);
  const template = container.firstElementChild;

  window.OMJobListTemplate = template.cloneNode(true);

  const pagerElement = document.getElementById(PAGER_CONTAINER_ID);
  if (pagerElement) {
    const pagerItem = pagerElement.querySelector("[data-jobs-pager-item]");

    if (pagerItem) {
      window.OMJobListPagerTemplate = pagerItem.cloneNode(true);
    }
  }

  const pagerBottomElement = document.getElementById(PAGER_BOTTOM_CONTAINER_ID);
  if (pagerBottomElement) {
    const pagerItem = pagerBottomElement.querySelector(
      "[data-jobs-pager-bottom-item]"
    );

    if (pagerItem) {
      window.OMJobListPagerBottomTemplate = pagerItem.cloneNode(true);
    }
  }
}
