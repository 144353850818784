import getPagerElement from "./getPagerElement";
import setPagesAttr from "./setPagesAttr";

function setExtraPagerElements(
  pagerSettings,
  allPageNum,
  pagerContainer,
  currentPageIndex,
  pagerElements
) {
  if (pagerSettings && allPageNum > 3) {
    const {
      showDots,
      showPrevNext,
      showFirstLast,
      showFirstLastNumber,
      prevText,
      nextText,
      firstText,
      lastText,
      dotsText,
    } = pagerSettings;

    let displayPages = [
      currentPageIndex,
      currentPageIndex + 1,
      currentPageIndex + 2,
    ];

    if (currentPageIndex === 0) {
      displayPages = [1, 2, 3];
    } else if (currentPageIndex + 1 === allPageNum) {
      displayPages = [allPageNum - 2, allPageNum - 1, allPageNum];
    }

    pagerElements.forEach((item, index) => {
      if (!displayPages.includes(index + 1)) {
        item.style.display = "none";
      }
    });

    const firstNumberPagerElement = getPagerElement(
      window.OMJobListPagerTemplate,
      1,
      1,
      "firstNumber"
    );

    const lastNumberPagerElement = getPagerElement(
      window.OMJobListPagerTemplate,
      allPageNum,
      allPageNum,
      "lastNumber"
    );

    const leftDotsPagerElement = getPagerElement(
      window.OMJobListPagerTemplate,
      currentPageIndex,
      dotsText || "...",
      "leftDots"
    );

    const rightDotsPagerElement = getPagerElement(
      window.OMJobListPagerTemplate,
      currentPageIndex + 2,
      dotsText || "...",
      "rightDots"
    );

    const prevPagerElement = getPagerElement(
      window.OMJobListPagerTemplate,
      currentPageIndex,
      prevText || "<",
      "prev"
    );

    const nextPagerElement = getPagerElement(
      window.OMJobListPagerTemplate,
      currentPageIndex + 2,
      nextText || ">",
      "next"
    );

    const firstPagerElement = getPagerElement(
      window.OMJobListPagerTemplate,
      1,
      firstText || "<<",
      "first"
    );

    const lastPagerElement = getPagerElement(
      window.OMJobListPagerTemplate,
      allPageNum,
      lastText || ">>",
      "last"
    );

    if (showDots) {
      pagerContainer.insertBefore(
        leftDotsPagerElement,
        pagerContainer.firstChild
      );
      pagerContainer.appendChild(rightDotsPagerElement);
    }

    if (showFirstLastNumber) {
      if (currentPageIndex > 1) {
        pagerContainer.insertBefore(
          firstNumberPagerElement,
          pagerContainer.firstChild
        );
      }

      if (currentPageIndex < allPageNum - 2) {
        pagerContainer.appendChild(lastNumberPagerElement);
      }
    }

    if (showPrevNext) {
      pagerContainer.insertBefore(prevPagerElement, pagerContainer.firstChild);
      pagerContainer.appendChild(nextPagerElement);
    }

    if (showFirstLast) {
      pagerContainer.insertBefore(firstPagerElement, pagerContainer.firstChild);
      pagerContainer.appendChild(lastPagerElement);
    }
  }
}

export default function setPager(
  allPageNum,
  pagerContainer,
  currentPageIndex,
  position
) {
  const pagerElements = Array.from({ length: allPageNum }, (v, k) =>
    getPagerElement(window.OMJobListPagerTemplate, k + 1, k + 1, "number")
  );

  const options = window.OMJobListOptions || {};
  const { pagerSettings } = options;

  if (pagerContainer) {
    pagerContainer.innerHTML = "";
    pagerElements.forEach((pagerElement) => {
      pagerContainer.appendChild(pagerElement);
    });

    setExtraPagerElements(
      pagerSettings,
      allPageNum,
      pagerContainer,
      currentPageIndex,
      pagerElements
    );

    setPagesAttr(allPageNum, currentPageIndex + 1, position);
  }
}
