import { PREFECTURES, FORM_CONTAINER_ID } from "@constants/index";
import { changePrefSelect } from "@handlers/job/list/change";

export default function createPrefectureCountInput(
  prefecture_data,
  queryString
) {
  const formContainer = document.getElementById(FORM_CONTAINER_ID);

  if (formContainer) {
    const prefectureInput = formContainer.querySelector(
      "[data-form-prefecture]"
    );

    if (prefectureInput) {
      prefectureInput.innerHTML = "";
      const labelsFragment = document.createDocumentFragment();

      prefecture_data.forEach((pref) => {
        const { number, label, count } = pref;

        const { hasCountBracket, isShowNoCount } =
          window.OMJobListOptions &&
          window.OMJobListOptions.hiringSystemSettings
            ? window.OMJobListOptions.hiringSystemSettings
            : {};

        if (!isShowNoCount && count == 0) {
          return;
        }

        const labelText = hasCountBracket ? `${label}(${count})` : `${label}`;
        const isSkipNoCount = !isShowNoCount;
        const isNoCount = count == 0;

        if (isSkipNoCount && isNoCount) {
          return;
        }

        const newInput = document.createElement("input");
        newInput.setAttribute("name", "om-forms-prefecture");
        newInput.setAttribute("type", "checkbox");
        newInput.setAttribute("value", number);
        newInput.disabled = isNoCount;

        const newSpan = document.createElement("span");
        const prefectureText = document.createTextNode(labelText);
        newSpan.appendChild(prefectureText);

        const newLabel = document.createElement("label");
        newLabel.appendChild(newInput);
        newLabel.appendChild(newSpan);
        newLabel.setAttribute("aria-disabled", isNoCount ? "true" : "false");

        labelsFragment.appendChild(newLabel);
      });

      prefectureInput.appendChild(labelsFragment);

      prefectureInput.addEventListener("change", function (e) {
        return changePrefSelect(e.target.value, queryString);
      });
    }
  }
}
