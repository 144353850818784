// export const DOMAIN = "http://localhost:8000";
export const DOMAIN = 'https://ownedmaker.com';
export const TAG_KEY = '5k2wMAXAn|h(A|qekV.HzQeEhekV(A|q.H-Gu';

export const CONTAINER_ID = 'om-jobs-container';
export const FORM_CONTAINER_ID = 'om-forms-container';
export const APPLY_FORM_CONTAINER_ID = 'om-apply-forms-container';
export const PAGER_CONTAINER_ID = 'om-jobs-pager';
export const PAGER_BOTTOM_CONTAINER_ID = 'om-jobs-pager-bottom';
export const RESULT_CONTAINER_ID = 'om-jobs-result';
export const SCRIPT_ID = 'om-script';
export const DUMMY_IMAGE_URL =
  'https://recpar-ats.s3.amazonaws.com/media/images/siteconfigure/photo/default.jpg';

export const WORK_JOB_VERSION = '2';

export const HIRING_SYSTEM = [
  [0, '正社員'],
  [1, '契約社員'],
  [2, '派遣社員'],
  [3, 'アルバイト・パート'],
  [4, '業務委託'],
  [5, 'インターンシップ'],
  [6, '新卒採用'],
  [7, '中途採用'],
  [8, 'その他'],
  [9, '紹介予定派遣'],
  [10, '請負'],
  [11, '嘱託社員'],
];

export const WORK_TYPES = [
  [1, '営業・販売'],
  [2, '組織運営管理・事務'],
  [3, '飲食'],
  [4, '生活関連・代行サービス'],
  [5, '清掃・緑地環境整備'],
  [6, '旅行・レジャー・イベント'],
  [7, '教育・学習支援'],
  [8, '福祉・葬祭・宗教'],
  [9, '医療'],
  [10, '保安・警備・安全管理'],
  [11, '交通・運輸'],
  [12, '農林漁業'],
  [13, 'メディア・クリエイティブ'],
  [14, '情報技術'],
  [15, '建築・エンジニアリング'],
  [16, '科学・研究'],
  [17, '建設・土木'],
  [18, '製造・生産工程'],
  [19, 'サプライチェーン・倉庫・物流'],
  [20, '整備・メンテナンス'],
  [21, 'マーケティング・広報・広告・宣伝'],
  [22, '金融'],
  [23, '法務'],
];

export const PREFECTURES = [
  [0, '北海道'],
  [1, '青森県'],
  [2, '岩手県'],
  [3, '宮城県'],
  [4, '秋田県'],
  [5, '山形県'],
  [6, '福島県'],
  [7, '茨城県'],
  [8, '栃木県'],
  [9, '群馬県'],
  [10, '埼玉県'],
  [11, '千葉県'],
  [12, '東京都'],
  [13, '神奈川県'],
  [14, '新潟県'],
  [15, '富山県'],
  [16, '石川県'],
  [17, '福井県'],
  [18, '山梨県'],
  [19, '長野県'],
  [20, '岐阜県'],
  [21, '静岡県'],
  [22, '愛知県'],
  [23, '三重県'],
  [24, '滋賀県'],
  [25, '京都府'],
  [26, '大阪府'],
  [27, '兵庫県'],
  [28, '奈良県'],
  [29, '和歌山県'],
  [30, '鳥取県'],
  [31, '島根県'],
  [32, '岡山県'],
  [33, '広島県'],
  [34, '山口県'],
  [35, '徳島県'],
  [36, '香川県'],
  [37, '愛媛県'],
  [38, '高知県'],
  [39, '福岡県'],
  [40, '佐賀県'],
  [41, '長崎県'],
  [42, '熊本県'],
  [43, '大分県'],
  [44, '宮崎県'],
  [45, '鹿児島県'],
  [46, '沖縄県'],
];

export const JOB_DETAIL_TEXT_ITEMS = [
  'item_name',
  'job_description',
  'url',
  'salary_pattern',
  'salary_description',
  'trial',
  'trial_condition',
  'trial_period',
  'trial_hiring_system',
  'trial_salary_pattern',
  'trial_description',
  'travel_cost',
  'travel_cost_description',
  'travel_cost_pattern',
  'travel_cost_day_cond',
  'travel_cost_month_cond',
  'environment_gender_ratio',
  'shift_income_example',
  'embed_url',
  'zip_code',
  'prefecture',
  'city',
  'building',
  'work_time',
  'work_flow',
  'work_type',
  'job_type',
  'work_type2',
  'job_type2',
  'hiring_system',
  'image1_caption',
  'image2_caption',
  'image3_caption',
  'image4_caption',

  'company_name',
  'worker_message',
  'work_location',
  'access',
  'period_min',
  'applied_flow',
  'recruit_number',
  'holiday_description',
  'welfare_description',
  'shift_circle',
  'recruit_number_description',

  'preventsmoke',
  'preventsmoke_description',
  'contractperiod',

  'sub_title',
  'qualifications',
  'other_description',
  'appeal_message',
  'contractperiod_description',
  'referral_company_name',

  'fixed_pay',
  'fixed_time',
  'legal_overtime',
  'fixed_overtime_pay_hour',
  'fixed_overtime_pay_system',
  'fixed_overtime_pay_time_range_type',
  'fixed_overtime_pay_add_pay_type',
  'fixed_overtime_pay_add_not_payment_reason',
  'fixed_overtime_pay_legal_overtime_hour',
  'fixed_overtime_pay_legal_overtime_minutes',

  'trial_fixed_pay',
  'trial_fixed_time',
  'trial_legal_overtime',
  'trial_fixed_overtime_pay_hour',
  'trial_fixed_overtime_pay_system',
  'trial_fixed_overtime_pay_time_range_type',
  'trial_fixed_overtime_pay_add_pay_type',
  'trial_fixed_overtime_pay_add_not_payment_reason',
  'trial_fixed_overtime_pay_legal_overtime_hour',
  'trial_fixed_overtime_pay_legal_overtime_minutes',

  'average_time',
  'actual_work',
  'average_work',
  'working_hours',
  'trial_average_time',
  'trial_actual_work',
  'trial_average_work',
  'trial_working_hours',
];

export const JOB_DETAIL_INT_ITEMS = [
  'salary_min',
  'salary_max',
  'trial_salary_min',
  'trial_salary_max',
];

export const JOB_DATE_ITEMS = ['created_at', 'updated_at'];

export const JOB_MULTI_FIELDS = [
  'work_week',
  'holiday',
  'welfare',
  'workstyle_experience',
  'workstyle_shift',
  'workstyle_time',
  'workstyle_location',
  'workstyle_other',
  'environment_age',
  'environment_mood',
  'job_features',
  'social_insurance',
];

export const JOB_IMAGE_FIELDS = ['image1', 'image2', 'image3', 'image4'];

export const ENTRY_FIELDS = [
  'first_name',
  'last_name',
  'first_name_kana',
  'last_name_kana',
  'phone',
  'gender',
  'email',
  'birth_day',
];

export const COMPANY_INFO_FIELDS = [
  'username',
  'site_url',
  'phone',
  'zip_code',
  'prefecture',
  'city',
  'building',
];

export const JOB_LINK_FIELDS = ['url'];
