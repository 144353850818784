export default function setInitialValue(selector, value) {
  if (!value) {
    return;
  }
  const element = document.querySelector(selector);

  if (element) {
    element.value = String(value);
    element.dispatchEvent(new Event("change"));
  }
}
