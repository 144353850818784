import { DOMAIN } from "@constants/index";
import { getURLParams } from "@utils/index";

export default function formatApplyLink(job) {
  const urlParams = getURLParams();
  const extraURLParamString = Object.keys(urlParams)
    .map(function (key) {
      return encodeURIComponent(key) + "=" + encodeURIComponent(urlParams[key]);
    })
    .join("&");

  return `${DOMAIN}/${job.company.site_url}/applicant/apply/${job.id}?${extraURLParamString}`;
}
