class Select {
  constructor(name, inputContainer, options, handleChange) {
    this.name = name;
    this.inputContainer = inputContainer;
    this.options = options;
    this.handleChange = handleChange;
  }

  update(selectElement) {
    selectElement.innerHTML = "";
    const optionsFragment = document.createDocumentFragment();
    this.options.forEach((item) => {
      const [value, label] = item;
      const itemOption = document.createElement("option");
      const itemLabel = document.createTextNode(label);

      itemOption.setAttribute("name", this.name);
      itemOption.setAttribute("value", value);
      itemOption.appendChild(itemLabel);

      optionsFragment.appendChild(itemOption);
    });

    selectElement.appendChild(optionsFragment);
  }

  create() {
    if (this.inputContainer) {
      const selectElement = document.createElement("select");
      selectElement.setAttribute("name", this.name);

      const optionsFragment = document.createDocumentFragment();
      this.options.forEach((item) => {
        const [value, label] = item;
        const itemOption = document.createElement("option");
        const itemLabel = document.createTextNode(label);

        itemOption.setAttribute("name", this.name);
        itemOption.setAttribute("value", value);
        itemOption.appendChild(itemLabel);

        optionsFragment.appendChild(itemOption);
      });

      selectElement.appendChild(optionsFragment);

      if (this.handleChange) {
        selectElement.addEventListener("change", this.handleChange);
      }
      this.inputContainer.appendChild(selectElement);
    }
  }
}

export default Select;
