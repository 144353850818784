import { APPLY_FORM_CONTAINER_ID } from "@constants/index";
import Text from "@ElementMaker/basic/Text";

export default function createNameInput() {
  const formContainer = document.getElementById(APPLY_FORM_CONTAINER_ID);

  if (formContainer) {
    const lastNameContainer = formContainer.querySelector(
      `[data-form-last_name]`
    );
    const firstNameContainer = formContainer.querySelector(
      `[data-form-first_name]`
    );

    new Text("last_name", lastNameContainer, "名前を入力（姓）").create();
    new Text("first_name", firstNameContainer, "名前を入力（名）").create();
  }
}
