import { APPLY_FORM_CONTAINER_ID } from "@constants/index";
import Button from "@ElementMaker/basic/Button";
import EntryError from "./EntryError";
import QuestionError from "./QuestionError";
import getEntryForms from "./getEntryForms";
import getQuestionForms from "./getQuestionForms";
import doPostRequest from "@modules/doPostRequest";
import getQueryString from "@modules/getQueryString";
import toggleLoader from "@modules/toggleLoader";

function safeNavigate({ url, isNewTab }) {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("rel", "noreferrer noopener");
  if (isNewTab) {
    link.setAttribute("target", "_blank");
  }
  document.body.appendChild(link);
  link.click();
}

function afterApply({ context, config }) {
  const DEFAULT_TEXT = {
    success: "応募が完了しました。",
    conflict: "すでに応募済みです。",
    error: "エラーが発生しました。",
  };
  if (config) {
    const { redirectUrl, isNewTab, action, message } = config;

    if (redirectUrl) {
      // 別ページへリダイレクトする場合
      safeNavigate({ url: redirectUrl, isNewTab: !!isNewTab });
    } else if (action) {
      // カスタムコードを実行させる場合
      action();
    } else {
      // リダイレクト・カスタムコードのいずれも指定されていない場合、アラートを表示する
      const defaultText = DEFAULT_TEXT[context];
      alert(message || defaultText);
    }
  } else {
    const defaultText = DEFAULT_TEXT[context];
    alert(defaultText);
  }
}

function handleEntryClick(formContainer) {
  const questionFormData = getQuestionForms();
  const questionError = new QuestionError(formContainer, questionFormData);
  questionError.getErrors();

  const formData = getEntryForms(formContainer);
  const entryError = new EntryError(formContainer, formData);
  entryError.getErrors();

  const postFormData = new FormData();
  formData["applicant_gender"] = formData["gender"];

  if (entryError.success && questionError.success) {
    Object.keys(formData).forEach((key) => {
      const value = formData[key];
      postFormData.append(key, value);
    });

    const { jobOfferId } = window.OMJobApplyInfo;
    postFormData.append("job_offer_id", jobOfferId);
    postFormData.append("agent", window.navigator.userAgent);
    postFormData.append("answers_json", JSON.stringify(questionFormData));

    const queryParam = getQueryString();
    postFormData.append("route", queryParam?.route || "");
    postFormData.append("utm_source", queryParam?.utm_source || "");
    postFormData.append("utm_medium", queryParam?.utm_medium || "");

    const { success, conflict, error } = window.OMJobApplyOptions;

    toggleLoader("[data-job-loader]", true);
    doPostRequest({
      path: "/applyJobOfferApi/",
      payload: postFormData,
      successFunc: function (response) {
        const { is_success: isSuccess, redirect_url: redirectApiUrl } =
          response?.data || {};
        const isConflict = redirectApiUrl === "/already_applied/";

        if (isSuccess) {
          afterApply({ context: "success", config: success });
        } else if (!isSuccess && isConflict) {
          afterApply({ context: "conflict", config: conflict });
        } else {
          afterApply({ context: "error", config: error });
        }
      },
      errorFunc: function () {
        afterApply({ context: "error", config: error });
      },
      finallyFunc: function () {
        toggleLoader("[data-job-loader]", false);
      },
    });
  }
}

function handleEntryClickDebug(buttonContainer) {
  const context =
    buttonContainer.getAttribute("data-form-debug_success") !== null
      ? "success"
      : buttonContainer.getAttribute("data-form-debug_conflict") !== null
      ? "conflict"
      : buttonContainer.getAttribute("data-form-debug_error") !== null
      ? "error"
      : null;

  const { success, conflict, error } = window.OMJobApplyOptions;

  toggleLoader("[data-job-loader]", true);
  switch (context) {
    case "success":
      afterApply({ context: "success", config: success });
      break;
    case "conflict":
      afterApply({ context: "conflict", config: conflict });
      break;
    case "error":
      afterApply({ context: "error", config: error });
      break;
  }
  toggleLoader("[data-job-loader]", false);
}

export default function createEntryButton() {
  const formContainer = document.getElementById(APPLY_FORM_CONTAINER_ID);

  if (formContainer) {
    const buttonContainers = formContainer.querySelectorAll(
      "[data-form-entry_button]"
    );

    if (buttonContainers) {
      buttonContainers.forEach((buttonContainer) => {
        new Button(buttonContainer, function (e) {
          handleEntryClick(formContainer);
          handleEntryClickDebug(buttonContainer);
        }).create();
      });
    }
  }
}
