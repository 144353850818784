export default function getQuestionForms() {
  const { questionJson } = window.OMJobApplyInfo;

  questionJson.forEach((question) => {
    const { type, uuid } = question;

    let answer = null;
    if (type === "checkbox") {
      const checkboxes = document.querySelectorAll(`[name="${uuid}"]`);
      answer = Array.from(checkboxes)
        .filter((checkbox) => checkbox.checked)
        .map((checkbox) => checkbox.value);
    } else {
      const input = document.querySelector(`[name="${uuid}"]`);
      answer = input.value;
    }

    question["answer"] = answer;
  });

  return questionJson;
}
