class Button {
  constructor(inputContainer, handleClick) {
    this.inputContainer = inputContainer;
    this.handleClick = handleClick;
  }

  create() {
    if (this.inputContainer) {
      this.inputContainer.addEventListener("click", this.handleClick);
    }
  }
}

export default Button;
