import doGetRequest from "@modules/doGetRequest";
import QueryString from "@modules/QueryString";
import getUrlParam from "@modules/getUrlParam";

import { TAG_KEY, CONTAINER_ID } from "@constants/index";

import getJobElement from "./modules/getJobElement";

export default function JobDetail(defaultProps) {
  const { params, options } = defaultProps || {};

  window.OMJobDetailOptions = options;
  window.OMJobDetailParams = params;

  if (params?.id || !!options?.urlIdParam) {
    const jobOfferId = !!options?.urlIdParam
      ? getUrlParam(options?.urlIdParam)
      : params?.id;

    const showPrivate = !!options?.showPrivate;

    const queryStringParams = {
      tag_key: TAG_KEY,
      show_private: showPrivate,
    };

    const container = document.getElementById(CONTAINER_ID);
    const template404 = document.getElementById("om-jobs-404");
    if (template404) {
      template404.style.display = "none";
    }
    const template500 = document.getElementById("om-jobs-500");
    if (template500) {
      template500.style.display = "none";
    }
    if (!container) {
      console.error("CONTAINER_ID(#om-jobs-container)が見つかりません。");
      return;
    }
    if (container.children.length > 1) {
      console.error(
        "CONTAINER_ID(#om-jobs-container)の子要素は１つである必要があります。"
      );
      return;
    }

    const queryString = QueryString.serializer(queryStringParams);
    doGetRequest({
      path: `/api/v1/tag/jobs/${jobOfferId}/?${queryString}`,
      successFunc: (res) => {
        const job = res.data.job_offer;
        job["social_insurance"] = job.indeed_taxonomy_moderation_socials_list;
        const template = container.firstElementChild;
        const jobElement = getJobElement(template, job);
        container.innerHTML = "";
        container.appendChild(jobElement);
      },
      errorFunc: (err) => {
        console.error(err);
        const { status } = err.response;
        if (status === 404 && template404) {
          template404.style.display = "block";
        }

        if (status === 500 && template404) {
          template500.style.display = "block";
        }

        container.innerHTML = "";
        container.style.display = "none";
      },
      finallyFunc: () => {},
    });
  } else {
    console.error("求人番号を設定してください。");
  }
}
