import {
  PAGER_CONTAINER_ID,
  PAGER_BOTTOM_CONTAINER_ID,
} from "@constants/index";

function setPagesStatus(allPageNum, currentPage, pageItems) {
  pageItems.forEach((item) => {
    const eachPage = item.dataset.jobsPage;
    const pagerType = item.dataset.pagerType;

    const leftTypes = ["prev", "first", "leftDots"];
    const rightTypes = ["next", "last", "rightDots"];

    let status = null;
    if (leftTypes.includes(pagerType) && currentPage === 1) {
      status = "disabled";
      if (pagerType === "leftDots") {
        item.style.display = "none";
      }
    } else if (rightTypes.includes(pagerType) && currentPage === allPageNum) {
      status = "disabled";
      if (pagerType === "rightDots") {
        item.style.display = "none";
      }
    } else if (parseInt(eachPage) === parseInt(currentPage)) {
      status = "current";
    }

    item.dataset.jobsPageStatus = status;
  });
}

export default function setPagesAttr(allPageNum, currentPage, position) {
  let pagerContainer = document.getElementById(PAGER_CONTAINER_ID);

  if (position === "bottom") {
    pagerContainer = document.getElementById(PAGER_BOTTOM_CONTAINER_ID);
  }

  if (pagerContainer) {
    const pageItems = pagerContainer.querySelectorAll(`[data-jobs-pager-item]`);
    const pageBottomItems = pagerContainer.querySelectorAll(
      `[data-jobs-pager-bottom-item]`
    );

    setPagesStatus(allPageNum, currentPage, pageItems);
    setPagesStatus(allPageNum, currentPage, pageBottomItems);
  }
}
