import { FORM_CONTAINER_ID } from "@constants/index";

import { changePrefSelect } from "@handlers/job/list/change";

export default function createPrefectureCountSelect(
  prefecture_data,
  queryString
) {
  const formContainer = document.getElementById(FORM_CONTAINER_ID);

  if (formContainer) {
    const prefSelect = formContainer.querySelector(
      "[data-form-count-prefecture]"
    );

    const hasDefaultText =
      window.OMJobListOptions &&
      window.OMJobListOptions.prefectureSettings &&
      window.OMJobListOptions.prefectureSettings.defaultText;

    const defaultText = hasDefaultText
      ? window.OMJobListOptions.prefectureSettings.defaultText
      : "すべて";

    const optionsFragment = document.createDocumentFragment();
    if (prefSelect) {
      prefSelect.innerHTML = `<option value="">${defaultText}</option>`;
      prefecture_data.forEach((pref) => {
        const { number, label, count } = pref;
        const { hasCountBracket, isShowNoCount } =
          window.OMJobListOptions && window.OMJobListOptions.prefectureSettings
            ? window.OMJobListOptions.prefectureSettings
            : {};

        if (!isShowNoCount && count == 0) {
          return;
        }

        const labelText = hasCountBracket ? `${label}(${count})` : `${label}`;
        const isSkipNoCount = !isShowNoCount;

        const isNoCount = count == 0;
        const newOption = document.createElement("option");
        const prefLabel = document.createTextNode(labelText);

        if (isSkipNoCount && isNoCount) {
          return;
        }

        newOption.setAttribute("value", number);
        newOption.disabled = isNoCount;
        newOption.setAttribute("aria-disabled", isNoCount ? "true" : "false");
        newOption.appendChild(prefLabel);

        optionsFragment.appendChild(newOption);
      });

      prefSelect.appendChild(optionsFragment);
      prefSelect.addEventListener("change", function (e) {
        return changePrefSelect(e.target.value, queryString);
      });
    }
  }
}
