import { FORM_CONTAINER_ID } from "@constants/index";

export default function createHiringSystemCountInput(hiringSystemData) {
  const formContainer = document.getElementById(FORM_CONTAINER_ID);

  if (formContainer) {
    const hiringSystemInputs = formContainer.querySelectorAll(
      "[data-form-hiringSystem]"
    );

    hiringSystemInputs.forEach((hiringSystemInput) => {
      // チェックボックステンプレートが存在すれば取得、なければのちに作成
      let labelTemplate = null;
      if (hiringSystemInput.children.length > 0) {
        labelTemplate = hiringSystemInput.children[0];
      }

      hiringSystemInput.innerHTML = "";
      const labelsFragment = document.createDocumentFragment();

      [...hiringSystemData].forEach((hiringSystem) => {
        const { number, label, count } = hiringSystem;

        const { hasCountBracket, isShowNoCount } =
          window.OMJobListOptions &&
          window.OMJobListOptions.hiringSystemSettings
            ? window.OMJobListOptions.hiringSystemSettings
            : {};

        if (!isShowNoCount && count == 0) {
          return;
        }

        const labelText = hasCountBracket ? `${label}(${count})` : `${label}`;
        const isSkipNoCount = !isShowNoCount;
        const isNoCount = count == 0;

        if (isSkipNoCount && isNoCount) {
          return;
        }

        if (labelTemplate) {
          const newContainerLabel = labelTemplate.cloneNode(true);
          newContainerLabel.setAttribute(
            "aria-disabled",
            isNoCount ? "true" : "false"
          );

          const newInput = newContainerLabel.querySelector("input");
          newInput.setAttribute("name", "om-forms-hiringSystem");
          newInput.setAttribute("type", "checkbox");
          newInput.setAttribute("value", number);
          newInput.disabled = isNoCount;

          const newSpan = newContainerLabel.querySelector("span");
          newSpan.textContent = labelText;

          labelsFragment.appendChild(newContainerLabel);
        } else {
          const newInput = document.createElement("input");
          newInput.setAttribute("name", "om-forms-hiringSystem");
          newInput.setAttribute("type", "checkbox");
          newInput.setAttribute("value", number);
          newInput.disabled = isNoCount;

          const newSpan = document.createElement("span");
          const hiringSystemText = document.createTextNode(labelText);
          newSpan.appendChild(hiringSystemText);

          const newContainerLabel = document.createElement("label");
          newContainerLabel.appendChild(newInput);
          newContainerLabel.appendChild(newSpan);
          newContainerLabel.setAttribute(
            "aria-disabled",
            isNoCount ? "true" : "false"
          );

          labelsFragment.appendChild(newContainerLabel);
        }
      });

      hiringSystemInput.appendChild(labelsFragment);
    });
  }
}
