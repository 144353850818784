export function getURLParams() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = {};
  urlSearchParams.forEach(function (value, key) {
    params[key] = value;
  });
  return params;
}

export function getURLParamString(params) {
  const paramString = Object.keys(params)
    .map(function (key) {
      return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
    })
    .join("&");

  return paramString;
}

export function getLinkSettings(linkSettings) {
  const { isPassLinkParams, route } = linkSettings;

  let paramString = "";
  if (isPassLinkParams) {
    const params = getURLParams();

    if (route) {
      params["route"] = route;
    }
    paramString = getURLParamString(params);
  }

  return paramString;
}

// urls: string[]
// 指定したURLのリンクにパラメータを付与する
export function passParamToLinks(urls) {
  const params = getURLParams();
  if (!params["route"]) {
    params["route"] = window.location.protocol + "//" + window.location.host;
  }

  const paramString = getURLParamString(params);
  const query = urls.map((url) => `a[href='${url}']`).join(", ");
  const linkElems = document.querySelectorAll(query);

  Array.prototype.slice.call(linkElems).forEach(function (link) {
    const originalLink = link.getAttribute("href");
    const paramSign = originalLink.includes("?") ? "&" : "?";
    link.setAttribute("href", `${originalLink}${paramSign}${paramString}`);
  });
}
