class ContainerHandler {
  constructor(job, label, tempTemplate) {
    this.job = job;
    this.label = label;
    this.containers = tempTemplate.querySelectorAll(
      `[data-job-container-${label}]`
    );
  }

  validator() {
    const value = this.job[this.label];
    const isInvalid = value === "" || !value;

    return isInvalid;
  }

  switchContainers() {
    const containers = this.containers;
    const isInvalid = this.validator();

    if (!!containers) {
      containers.forEach((container) => {
        if (isInvalid) {
          container.setAttribute("style", "display: none;");
        }
      });
    }
  }
}

class MultiChoiceContainerHandler extends ContainerHandler {
  validator() {
    const value = this.job[this.label];
    const isInvalid = !value || value.length === 0;
    return isInvalid;
  }
}

class FeatureContainerHandler extends ContainerHandler {
  validator() {
    const workstyleExperience = this.job["workstyle_experience"];
    const workstyleOther = this.job["workstyle_other"];
    const workstyleTime = this.job["workstyle_time"];
    const features = [
      ...(workstyleExperience || []),
      ...(workstyleOther || []),
      ...(workstyleTime || []),
    ];
    const isInvalid = features.length === 0;

    return isInvalid;
  }
}

class TrialContainerHandler extends ContainerHandler {
  validator() {
    const showTrial = this.job["show_trial"];
    const isInvalid = !showTrial;

    return isInvalid;
  }
}

class TrialWorkTimeContainerHandler extends ContainerHandler {
  validator() {
    const trialCond = this.job["trial_condition"];
    const showTrial = this.job["show_trial"];
    const isTrial = this.job["trial"] === "あり";
    const isInvalid = trialCond === "本採用時と同じ" || !showTrial || !isTrial;

    return isInvalid;
  }
}

class TravelCostContainerHandler extends ContainerHandler {
  validator() {
    const travelCost = this.job["travel_cost"];
    const isInvalid = travelCost !== "あり";

    return isInvalid;
  }
}

class JobNumberContainerHandler extends ContainerHandler {
  validator() {
    const jobNumber = this.job["job_number"];
    const isInvalid =
      jobNumber === "" || jobNumber === null || !this.job["show_job_number"];

    return isInvalid;
  }
}

class FixedOvertimePaySystemContainerHandler extends ContainerHandler {
  validator() {
    const salaryPattern = this.job["salary_pattern"];
    const isInvalid = salaryPattern === "時給";

    return isInvalid;
  }
}

class CompanyInfoContainerHandler extends ContainerHandler {
  validator() {
    switch (this.label) {
      case "company_username":
        return !this.job["show_company_name"];

      case "company_address":
        return !this.job["show_address"];

      case "company_phone":
        return !this.job["show_phone"];

      default:
        return true;
    }
  }
}

export {
  ContainerHandler,
  MultiChoiceContainerHandler,
  FeatureContainerHandler,
  TrialContainerHandler,
  TrialWorkTimeContainerHandler,
  TravelCostContainerHandler,
  JobNumberContainerHandler,
  FixedOvertimePaySystemContainerHandler,
  CompanyInfoContainerHandler,
};
